export default {
  items: [
    {
      id: 'Dashboard',
      title: 'Insurance Cover',
      type: 'group',
      icon: '',
      children: [
          // {
          //     id: 'myChats',
          //     title: 'Chats with clinics',
          //     type: 'item',
          //     url: '/dashboard/chats',
          //     icon: 'feather icon-message-square',
          // },
          {
              id: 'myInsurances',
              title: 'My Insurance Policies',
              type: 'item',
              url: '/dashboard/inquiries?page=1&itemsPerPage=10',
              icon: 'feather icon-list',
          },
          {
              id: 'myConsultation',
              title: 'My Consultations',
              type: 'item',
              url: '/dashboard/consultations?page=1&itemsPerPage=10',
              icon: 'feather icon-clipboard',
          },
          {
              id: 'treatmentPlanner',
              title: 'Treatment Planner',
              type: 'item',
              url: '/dashboard/treatmentPlans?page=1&itemsPerPage=10',
              icon: 'feather icon-smartphone',
          },
      ]
    },
  ]
}
